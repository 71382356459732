

.block_video_with_consent {
    --border-radius: var(--radius-base);
    --aspect-ratio: 16/9;
    
    --thumnail-overlay: hsla(var(--clr-ligth-green-2), .4); 
    
    --button-color: var(--text-primary);
    --button-play-radius: 50%;
    --button-bg-color: var(--bg-default);
    --button-shadow: var(--shadow-1);
    
    --footer-title-size: var(--fs-base);
    --footer-title-weight: var(--fw-primary-bold);
    --footer-title-color: var(--text-primary);
    --footer-title-ff: var(--ff-primary);

    --footer-text-size: var(--fs-sm);
    --footer-text-color: var(--text-primary);
    --footer-text-ff: var(--ff-primary);

    --footer-bg-color: var(--bg-secondary);
    --footer-icon-size: 1.5rem;

    
    
    position: relative;
  
    border-radius: var(--border-radius);
    overflow: hidden;
    background: black;
    display: flex;
    flex-direction: column;
    border: var(--border-primary);
    
    .video_thumnail, iframe {
        aspect-ratio: var(--aspect-ratio);
        width: 100%;
      
    }
    .video_thumnail {
        position: relative;
        background: linear-gradient(var(--thumnail-overlay),var(--thumnail-overlay) ),var(--url-thumnail) center center; 
        background-size: cover;


        button.video_active_zone {
            border: none;
            display: flex;
            position: absolute;
            align-items: center;
            color: var(--button-color);
            justify-content: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: transparent;


            &:hover, &:focus {
                cursor: pointer;
                background: transparent;
                animation: none;
                border: none;
                .video__play_button {
                    transform: scale(1.1);
                }

            }
            &:focus{
                outline: var(--focus-outline-tertiary);
            }

            .video__play_button {
                transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
                padding-left: var(--spacing-8);
                padding-right: var(--spacing-6);
                padding-top: var(--spacing-6);
                padding-bottom: var(--spacing-6);
                margin-top:1rem;
                border-radius: var(--button-play-radius);
                background: var(--button-bg-color);
                box-shadow: var(--button-shadow);
            }
        }
        
    }

    footer {
        display: flex;
        background: var(--footer-bg-color);
        margin-left: 0;
        border: none;     
        

        .video__title {
            font-family: var(--footer-title-ff);
            font-size: var(--footer-title-size);
            font-weight: var(--footer-title-weight);
            color: var(--footer-title-color);
        }
        p {
            margin-top: var(--spacing-2);
            margin-bottom: var(--spacing-2);
            font-family: var(--footer-text-ff);
            color: var(--footer-text-color);
            font-size: var(--footer-text-size);
        }

        a svg {
            width: var(--footer-icon-size);
            height: var(--footer-icon-size);
        }
        figcaption {
            margin-top: var(--spacing-2);
            margin-bottom: var(--spacing-2);
        }

    }
  
  
  
  
    
   
  
   
  }