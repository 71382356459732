
.site-header{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: var(--bg-theme);
	z-index: var(--z-index-forground-1);
	border-bottom: var(--border-primary);
	padding-top: var(--spacing-6);
	padding-bottom: var(--spacing-6);
	transition: var(--transition-fast);


	.logo-site {
		max-width: 10rem;
		transition: var(--transition-fast);
	}

	&.small {
		padding-top: var(--spacing-2);
		padding-bottom: var(--spacing-2);
		transition: var(--transition-fast);

		.logo-site {
			max-width: 6.5rem;
			transition: var(--transition-fast);

		}
	}


	/* ON DESKTOP */
	@media screen and (min-width: 1024px) {
		.header-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		nav {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			
			
			ol{
				display: flex;

				li.has-submenu {
					position: relative;
					
					&:hover,
					&:focus-within {
						cursor: pointer;
						ul.submenu {
							display: block;
						}
					}
					
					ul.submenu {
						display: none;
						position: absolute;
						top: 100%;
						left: 50%;
						overflow: hidden;
						padding-top: var(--spacing-2);
						padding-bottom: var(--spacing-2);
						border-radius: var(--radius-base);
						background: var(--bg-theme);
						box-shadow: var(--shadow-1);
						z-index: var(--z-index-forground-1);
						transform: translate(-50%);
						a {
							font-size: var(--fs-sm);
						}
;
					}
				}
			}

		}
	}
	
	/* ON MOBILE */
	@media screen and (max-width: 1024px) {	
		
		&[data-status="folded"] {
			nav {
				display: none;
				visibility: hidden;
				opacity: 0;
				
			}
		}
	
	
		&[data-status="unfolded"] {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			animation-name: open;
			animation-fill-mode: forwards;
			animation-duration: 400ms;
			animation-timing-function: ease-out;
			
			nav {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: calc(100vh - 7rem);


				ol {
					display: flex;
					flex-direction: column;
					justify-content: center;

					a {
						font-size: var(--fs-xl);
					}
					.has-submenu a{
						padding-left: 1rem;
					}
					.label {
						svg {
							display: none;
						}
						font-size: var(--fs-base);
						color: var(--text-secondary);
					}

					li {
						animation-name: slide-in;
						animation-fill-mode: forwards;
						animation-duration: var(--transition-duration, 300ms);
						opacity: 0;

						
						
						--base-delay: 0.1s;
						&:nth-child(1) {
							animation-delay: calc(var(--base-delay) * 1);
						}
						&:nth-child(2) {
							animation-delay: calc(var(--base-delay) * 2);
						}
						&:nth-child(3) {
							animation-delay: calc(var(--base-delay) * 3);
						}
						&:nth-child(4) {
							animation-delay: calc(var(--base-delay) * 4);
						}
						&:nth-child(5) {
							animation-delay: calc(var(--base-delay) * 5);
						}
						&:nth-child(6) {
							animation-delay: calc(var(--base-delay) * 6);
						}
						&:nth-child(7) {
							animation-delay: calc(var(--base-delay) * 7);
						}
					}
				}
			
			}
		}
	}
}


@keyframes slide-in {
    0% {
		transform: translateX(2rem);
		opacity: 0;
	}
    100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes open {
	0% {
		bottom: 80vh;

	}
	100% {
		bottom: 0;

	}
}