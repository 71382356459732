html {
    font-family: var(--ff-primary);
    font-weight: var(--fw-primary-regular);
    color: var(--text-primary);
    font-size: var(--fs-base);
}

.section-py {
    padding-top: var(--py-section-base);
    padding-bottom: var(--py-section-base);
}


[data-theme] {
    color: var(--text-primary);
    fill: var(--text-primary);
    background-color: var(--bg-theme);
}


.grid-3-col {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}


.container-1col {
    display: grid;
    grid-template-columns:
        minmax(var(--gutter-base), 1fr ) 
        [content-start] min(100% - (var(--gutter-base) * 2), var(--content-max-width)) [content-end] 
        minmax(var(--gutter-base), 1fr);
    & > * {
        grid-column: content;
    }
}

.container-2cols {
    display: grid;
    grid-template-columns: 
        var(--gutter-base) 
        [content-start] 1fr [content-end] 
        var(--gutter-base);
    & > * {
        grid-column: content ;
    }

    @media (min-width: 768px) {
        grid-template-columns: 
            minmax(var(--gutter-base), 1fr)  
            [col1-start] min(50% - (var(--gutter-base)*1.5), calc((var(--content-max-width) / 2) - var(--gutter-base)/2)) [col1-end]
            var(--gutter-base) 
            [col2-start] min(50% - (var(--gutter-base)*1.5), calc((var(--content-max-width) / 2) - var(--gutter-base)/2)) [col2-end]
            minmax(var(--gutter-base), 1fr) ;
  
        & > *:nth-child(odd) {
            grid-column: col1;
        }
        & > *:nth-child(even) {
            grid-column: col2;
        }
    }

    
}