.menu-link {
    &:hover {
        background: var(--bg-secondary);
    }
    svg {
        margin-top: .11rem;
        width: 1.2rem;
        height: 1.2rem;
    }
}

.block-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-4)
}

p a, a.underline {
    text-decoration: underline;
    text-underline-offset: var(--link-underline-offset);
    text-decoration-thickness: var(--link-decoration-thickness);

    &:hover, &:focus {
        background: var(--bg-secondary);
    }
}

.button-icon {
    display: flex;
    svg {
        fill: var(--text-primary);
        height: 2.8rem;
        width: 2.8rem;
    }
    &:hover, &:focus {
        background: var(--bg-secondary);
    }
}


.button {
    --slide-width: 2rem;

    position: relative;
    display: flex;
    justify-self: flex-start;
    color: var(--text-primary-contrast);
    border: var(--border-size) solid var(--border-clr-primary);
    border-radius: 2rem;
    overflow: hidden;

    padding-right: var(--slide-width);
    transition: var(--transition-fast);
    

    .wrapper {
        border-radius: 2rem;
        background: var(--bg-contrast);
        padding: 0.5rem 1rem;
        z-index: var(---index-forground-1);
    }
    svg {
        position: absolute;
        right: 0;
        color: var(--text-primary);
        margin: 0.5rem ;
        z-index: 0;
        transform: scale(1);
    }

    &:hover,
    &:focus {
        padding-right: 0;
        padding-left: var(--slide-width);

        svg {
            transform: scale(0);
            transition: var(--transition-fast);
        }


    }
    

}