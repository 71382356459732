.blog-card {
    --size-button: 2.5rem;
    --bg-card: var(--bg-theme, white);


    position: relative;
    border: var(--border-primary);
    border-radius: var(--radius-base, .25rem);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.covered {
        justify-content: space-between;
    }

    background: var(--bg-card);
    &:hover {
        box-shadow: var(--shadow-1);
        .button-pill {
            &::before {
                transform: translateX(0);
                transition: var(--transition-fast);
            }
            svg    {
                transform: translateX(100%);
                transition: var(--transition-fast);
                
            }
        }
    }

    .metas {
        color: var(--text-primary, black);
        padding: var(--spacing-4, 1rem) var(--spacing-4, 1rem) var(--spacing-3, 1rem);
        
        .infos {
            font-size: var(--fs-sm, 1rem);
            font-weight: var(--fw-primary-ligth);
        }
        .title {
            font-weight: var(--fw-primary-medium);
            text-wrap: balance;

            a {
                color: var(--text-primary, black);
                font-family: inherit;
                font-size: var(--text-2xl, 1.5rem);
                text-decoration: none;
                transition: var(--transition-fast);
                text-decoration-thickness: 0;
                text-underline-offset: 0;
                &:hover {
                    text-decoration: underline;
                    text-underline-offset: var(--link-underline-offset);
                    text-decoration-thickness: var(--link-decoration-thickness);
                    transition: var(--transition-fast);
                }
            }

            a::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 11;
            }
        }
    }
    .illustration {
        figure {
            line-height: 0;
            picture img{
                aspect-ratio: 3/2;
                object-fit: cover;
            }
        }
        img {
            width: 100%;
            height: auto;
        }
        p {
            font-size: var(--fs-sm);
            padding: 0 var(--spacing-4, 1rem) var(--spacing-4, 1rem);
            padding-bottom: calc(var( --size-button, 2.5rem) + var(--spacing-8, 1rem));
        }
    }
    
    .button-pill {
        position: absolute;
        bottom: var(--spacing-4, 1rem);
        right: var(--spacing-4, 1rem);
        border-radius: 100%;
        border:  var(--border-primary);
        z-index: 1;
        overflow: hidden;
        background: var(--background-primary, white);
        height: var(--size-button);
        width: var(--size-button);

        svg {
            height: calc(var(--size-button) - var(--border-size));
            width: calc(var(--size-button) - var(--border-size));
            transition: var(--transition-fast);
        }

        &::before {
            position: absolute;
            content: '';
            mask: url('../imgs/arrow-right-up-line.svg') no-repeat;
            mask-size: contain;
            height: var(--size-button);
            width: var(--size-button);
            background: var(--text-primary);
            transform: translateX(-100%);
            transition: var(--transition-fast);

        }

    }
}

.blog_card.featured {
    @include grid-col-2(var(--spacing-4), 768px);
    .title {
        font-size: var(--text-3xl, 1.5rem);
    }
    .illustration p {
        font-size: var(--text-2xl, 1.5rem);
        padding-right: calc(var( --size-button, 2.5rem) + var(--spacing-8, 1rem));
    }


}