.py-section-base {
    padding-top: var(--py-section-base);
    padding-bottom: var(--py-section-base);
}

.flow > * + * {
    margin-top: var(--flow-spacing, 1em);
}
.flow-lg > * + * {
    margin-top: calc(var(--flow-spacing, 1em) * 2);
}

.max-w-half-container {
    max-width: calc(var(--content-max-width)/2)
}

.flex-col-distribute {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.border-t-primary {
    border-top: var(--border-primary);
}

p.small {
    font-size: var(--fs-sm);
}