
.section-hero {
    h1, h1 strong {
        font-size: var(--fs-4xl);
        font-weight: var(--fw-primary-bold);
    }
}
[class^="section-block"]{
    border-top: var(--border-primary);
}
[class^="section-block"] {
    padding-top: var(--py-section-base);
    padding-bottom: var(--py-section-base);

    
    h1, h2, h3, h4, h5, h6  {
        font-weight: var(--fw-primary-bold);
        line-height: var(--lh-sm, 1.2);
    }

  


    h2, h2 strong {
        font-size: var(--fs-3xl);
        font-weight: var(--fw-primary-bold);
    }

    h3, h3 strong {
        font-size: var(--fs-xl);
        font-weight: var(--fw-primary-bold);
    }
    h4, h4 strong {
        font-size: var(--fs-lg);
        font-weight: var(--fw-primary-bold);
    }
    p {
        font-size: var(--fs-base);
        line-height: var(--lh-base, 1.5);
    }


    ul li, ol li {
        font-size: var(--fs-base);
        line-height: var(--lh-base, 1.5);
        margin-left: 1.4rem;
    }
    ol li {
        list-style: decimal;
    }
    ul li {
        list-style: disc;
    }


}



.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    font-size:var(--text-sm);
    opacity: 0.85;
    li {
        display: inline;
        &:not(:last-child):after {
            content: '>';
            margin: 0 0.5rem;
        }
        a {
            font-size: var(--text-sm);
            &:hover {
                text-decoration: underline;
                text-underline-offset: 0.2rem;
            }
            
        }
    }
}




