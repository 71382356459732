.pagination {
    padding-top: var(--spacing-8, 2rem);
    padding-bottom: var(--spacing-8, 2rem);

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-4, 1rem);



        .selector-page {
            display: flex;
            flex-direction: column;
        }
        
        form {
            gap: 1rem;
            display: flex;
            align-items: center;

            select {
                padding: .3rem .5rem;
                border: var(--border-primary);
                border-radius: var(--radius-base);
            }
        }



        .btn-icon {
            --size: 2.5rem;
            color: var(--body-color);
            width: var(--size);
            height: var(--size);
            svg {
                width: var(--size);
                height: var(--size);
            }
    
            &[aria-disabled="true"] {
                opacity: .5;
                cursor: not-allowed;
            }

            &:hover:not([aria-disabled="true"]), &:focus {
                background: var(--bg-secondary);
            }
        }
    }



   
}