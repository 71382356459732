@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@import "./cube/composition";

@import "./cube/utility";

@import "./cube/block";
@import "./cube/block/menu-icon";
@import "./cube/block/site-header";
@import "./cube/block/blog-card";
@import "./cube/block/buttons";
@import "./cube/block/block-image";
@import "./cube/block/pagination";
@import "./cube/block/block-video";


@import "./cube/exception";


img[data-lazy-src]{will-change:contents}

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/WorkSans-VariableFont_wght.woff2') format('woff2'),
        url('../fonts/WorkSans-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
    font-display: swap;
}


@layer base {
    :root {
        /* COLORS */

        /* PRIMITIVES */
        /* For hsl(198deg 93% 60% / <alpha-value>) */
        --clr-dark-blue-1: 250, 55%, 40%;
        --clr-ligth-green-1: 155, 64%, 46%;
        --clr-ligth-green-2: 153, 56%, 94%;
        
        --dark-blue-1: hsl(var(--clr-dark-blue-1,DarkSlateGray));
        --dark-blue-2: hsl(var(--clr-dark-blue-1,SlateGray), 80%);
        --ligth-green-1: hsl(var(--clr-ligth-green-1,LightGray));
        --ligth-green-2: hsl(var(--clr-ligth-green-2,Gainsboro));
        --light-white-1: white;

        /* Backgrounds */
        --bg-default: var(--light-white-1, WhiteSmoke);
        --bg-primary: var(--dark-blue-1, DarkSlateGray);
        --bg-secondary: var(--ligth-green-2, Gainsboro);
        --bg-tertiary: var(--ligth-green-1, Gainsboro);
        
        /* BUTTONS */
        --button-primary-bg:  var(--dark-blue-1);
        --button-primary-text: var(--light-white-1, WhiteSmoke);
        --button-primary-hover-bg: var(--dark-blue-2);


        /* FONTS */
        --ff-primary: 'Work Sans', sans-serif;
        --fw-primary-ligth: 400;
        --fw-primary-regular: 500;
        --fw-primary-medium: 700;
        --fw-primary-bold: 800;

        --lh-base: 1.5;
        --lh-sm: 1.15;


        /* Links underline */
        --link-underline-offset: 0.2rem;
        --link-decoration-thickness: 0.1rem;

        /* COMPOSITION SIZES */
        --flow-spacing: 1em;

        --gutter-base: clamp(1rem, 3.5vw, 3.5rem);
        --gutter-lg: clamp(4rem, 10vw, 10rem);

        --z-index-forground-1: 30;        
        
        --radius-base: 1rem;
        --radius-sm: .5rem;
        
        --shadow-1: 0 0 1rem rgba(0,0,0,0.1);
        
        --py-section-base: clamp(4rem, -2rem + 16vw, 6rem);
        

        /* CONTENT MAX WIDTH */
        --content-max-width: 80ch;
        [data-container="lg"]{
            --content-max-width: 120ch;
        }

    
        /* TRANSITIONS */
        --transition-bezier: cubic-bezier(.23,1,.32,1);
        --transition-duration-base: 1s;
        --transition-element: all;
        --transition: var(--transition-element) var(--transition-duration-base, .3s) var(--transition-bezier, ease-in-out);
        --transition-fast: var(--transition-element) .4s var(--transition-bezier, ease-in-out);


        /* THEMES */
        --text-primary:  var(--dark-blue-1);
        --text-primary-contrast: var(--light-white-1);
        --text-secondary: var(--dark-blue-2);
        --text-accent: var(--dark-blue-1);
        --bg-theme: var(--bg-default);
        --bg-contrast: var(--bg-primary);
        --border-clr-primary: var(--dark-blue-1);
        
        /* BORDER */
        --border-size: .15rem;
        --border-primary: var(--border-size) solid var(--border-clr-primary, black);


        [data-theme="primary"]{
            --text-primary: var(--light-white-1);
            --text-primary-contrast: var(--dark-blue-1);
            --text-secondary: var();
            --text-accent: var(--light-white-1);
            --bg-theme: var(--bg-primary);
            --bg-contrast: var(--bg-default);
            --border-clr-primary: var(--light-white-1);
        }
        
        [data-theme="secondary"]{
            --text-primary:  var(--dark-blue-1);
            --text-secondary: var(--dark-blue-2);
            --text-primary-contrast: var(--dark-blue-1);
            --text-accent: var(--dark-blue-1);
            --bg-theme: var(--bg-secondary);
            --bg-contrast: var(--bg-tertiary);
            --border-clr-primary: var(--ligth-green-1);
        }

        /* SPACING */
        --spacing-1: clamp(0.2rem, 0.25rem + 0.02vw, 0.6rem);
        --spacing-2: clamp(0.4rem, 0.5rem + 0.04vw, 1.2rem);
        --spacing-3: clamp(0.6rem, 0.75rem + 0.06vw, 1.8rem);
        --spacing-4: clamp(0.8rem, 1rem + 0.08vw, 2.4rem);
        --spacing-5: clamp(1rem, 1.25rem + 0.1vw, 3rem);
        --spacing-6: clamp(1.2rem, 1.5rem + 0.12vw, 3.6rem);
        --spacing-8: clamp(1.6rem, 2rem + 0.16vw, 4.8rem);
        --spacing-10: clamp(2rem, 2.5rem + 0.2vw, 6rem);
        --spacing-12: clamp(2.4rem, 3rem + 0.24vw, 7.2rem);
        --spacing-16: clamp(3.2rem, 4rem + 0.32vw, 9.6rem);
        --spacing-20: clamp(4rem, 5rem + 0.4vw, 12rem);
        --spacing-24: clamp(4.8rem, 6rem + 0.48vw, 14.4rem);
        --spacing-32: clamp(6.4rem, 8rem + 0.64vw, 19.2rem);
        --spacing-40: clamp(8rem, 10rem + 0.8vw, 24rem);
        --spacing-48: clamp(9.6rem, 12rem + 0.96vw, 28.8rem);
        --spacing-56: clamp(11.2rem, 14rem + 1.12vw, 33.6rem);
        --spacing-64: clamp(12.8rem, 16rem + 1.28vw, 38.4rem);
        
        /* TYPOGRAPHY */
        --fs-xs: clamp(0.75rem, calc(0.75rem + 0.02vw), 0.875rem);
        --fs-sm: clamp(0.875rem, calc(0.85rem + 0.08vw), 1rem);
        --fs-base: clamp(1rem, calc(0.92rem + 0.5vw), 1.15rem);
        --fs-lg: clamp(1.125rem, calc(0.98rem + 1vw), 1.375rem);
        --fs-xl: clamp(1.25rem, calc(1.06rem + 1.5vw), 1.75rem);
        --fs-2xl: clamp(1.5rem, calc(1.21rem + 2.03vw), 1.875rem);
        --fs-3xl: clamp(1.875rem, calc(1.37rem + 3.15vw), 2.25rem);
        --fs-4xl: clamp(2.25rem, calc(1.54rem + 4.74vw), 3rem);
        --fs-5xl: clamp(3rem, calc(1.7rem + 6.66vw), 3.75rem);
        --fs-6xl: clamp(3.75rem, calc(1.85rem + 9.99vw), 4.5rem);
        --fs-7xl: clamp(4.5rem, calc(2rem + 14.99vw), 6rem);
        --fs-8xl: clamp(6rem, calc(2.2rem + 22.48vw), 8rem);
        --fs-9xl: clamp(8rem, calc(2.4rem + 33.72vw), 10rem);
   

    }
}